@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.header {
    position: fixed;
    z-index: 2;
    
    display: flex;
    justify-content: space-between;

    background-color: $dark;
    padding: 1rem 2rem;
    width: 100%;
}