@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.active {
    position: relative;
    top: 4rem;

    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;

    background-color: $background;
    padding: 1rem 2rem;
    min-height: calc(100vh - 4rem);


    
    &__selected {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: calc(50vw - 6rem);
        height: calc(100% - 10rem);
        position: fixed;
        align-items: center;
        left: 4rem;
        top: 7rem;
    }

    &__visual {
        height: 60%;
    }

    &_tilt {
        height: 100%;
    }

    &__card-img {
        border-radius: 14px;
        height: 100%;
        box-shadow: $box-shadow;
    }




    &__right {
        width: 50%;
        height: 100%;
        padding: .5rem;
    }

    &__decklist-name {
        border-bottom: 1px solid $primary-text;
        margin: .5rem;
        width: 45%;
    }

    &__type {
        margin: .5rem;
        min-width: 10rem;
    }
}