/* Media Size */
$tablet-screen-size: 768px;
$desktop-screen-size: 1280px;

/* Padding & Margin Settings */
$margin-mb: 1rem;
$margin-tb: 2rem;

$gutter-mb: 1rem;
$gutter-tb: 1.5rem;

/* Colors */ 
// Primary
$primary-text: rgb(231, 230, 231);
$accent: #ad4c1c;

// Secondary
$secondary: #9c9a9b;

// Background 
$background: #252128;
$dark: #121112;
$side: #1b181d;

// Supporting
$valid: #158463;
$error: #C94515;

/* Others */
$box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.3);