@use "./styles/partials/fonts";
@use "./styles/partials/text";
@use "./styles/partials/mixins" as *;
@use "./styles/partials/variables" as *;

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: TitilliumWeb;
}

a {
  text-decoration: none;
}