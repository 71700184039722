@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.details {
    &__container {
        height: 40%;
        width: 70%;
        background-color: $dark;
        box-shadow: $box-shadow;
        padding: 0.5rem 1rem;
        border-radius: 10px;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        /* Customize the scrollbar track */
        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Customize the scrollbar thumb (the draggable part) */
        &::-webkit-scrollbar-thumb {
            background-color: $secondary;
            border-radius: 5px;
        }

        /* Customize the scrollbar thumb when the user hovers over it */
        &::-webkit-scrollbar-thumb:hover {
            background-color: $primary-text;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 1px;

        padding: 0.5rem 0;
        border-top: 1px solid $secondary;
        border-bottom: 1px solid $secondary;
    }

    &__pt {
        display: flex;
        gap: 1rem;
    }

    &__mana-cost {
        display: flex;
        align-items: center;
        padding: 0.25rem 0;
        gap: 0.125rem;
    }

    &__symbol {
        height: .75rem;
        flex-shrink: 0;
        margin: 1px;
        border-radius: 50%;
        vertical-align: sub;
    }

    &__card-text {
        display: inline;
    }

    &__text {
        white-space: pre-line;
        display: inline-flex;
        align-items: center;
    }

}