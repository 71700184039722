@use './_variables' as *;

/* Media Query */
@mixin tablet {

    // 768px <
    @media screen and (min-width: $tablet-screen-size) {
        @content;
    }
}

@mixin desktop {

    // 1280px < 
    @media screen and (min-width: $desktop-screen-size) {
        @content;
    }
}

/* Text-Style */

@mixin p-large {
    font-size: 0.9375rem; // 15px
    line-height: 1.625rem; // 26px

    @include tablet {
        font-size: 1rem; // 16px
        line-height: 1.75rem; // 28px
    }
}

@mixin p-small {
    font-size: 0.6875rem; // 11px
    line-height: 1rem; // 16px

    @include tablet {
        font-size: 0.75rem; // 12px
        line-height: 1.125rem; // 18px
    }
}