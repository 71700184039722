@use './_mixins' as *;
@use './_variables' as *;

h1 {
    font-size: 1.75rem; // 28px
    line-height: 2.25rem; // 36px
    color: $primary-text;
    font-weight: 600;

    @include tablet {
        font-size: 2rem; // 32px
        line-height: 2.5rem; // 40px
    }
}

h2 {
    font-size: 1.25rem; // 20px
    line-height: 1.75rem; // 28px
    color: $primary-text;
    font-weight: 600;

    @include tablet {
        font-size: 1.5rem; // 24px
        line-height: 2rem; // 32px
    }
}

h3,
label,
a,
button {
    font-size: 0.8125rem; // 13px
    line-height: 1.25rem; // 20px
    color: $secondary;
    font-weight: 600;

    @include tablet {
        font-size: 0.875rem; // 14px
        line-height: 1.375rem; // 22px
    }
}

h4 {
    font-size: 0.6875rem; // 11px
    line-height: 1rem; // 16px
    color: $secondary;
    text-transform: uppercase;

    @include tablet {
        font-size: 0.75rem; // 12px
        line-height: 1.125rem; // 18px
    }
}

p {
    font-size: 0.8125rem; // 13px
    line-height: 1.25rem; // 20px
    color: $primary-text;

    @include tablet {
        font-size: 0.875rem; // 14px
        line-height: 1.375rem; // 22px
    }
}