@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    margin: .5rem;
    width: 60%;

    &__bar {
        width: 80%;
        outline: none;
        background-color: $dark;
        color: $primary-text;
        padding: 0.25rem .5rem;
        border: none;

        &::placeholder {
            color: $primary-text;
        }
    }

    &__results {
        top: 28px;
        position: absolute;
        z-index: 2;
        white-space: nowrap;
        background-color: $dark;
        min-width: 15rem;
        padding: 0 .5rem;
        box-shadow: $box-shadow;
    }

    &__loading {
        padding: 0 .5rem;
    }

    &__option {
        color: $primary-text;
        padding: .25rem;
        border-top: 1px solid $secondary;

        &:first-child {
            border-top: none;
        }

        &:hover {
            background-color: $accent;
        }
    }
}