@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.dashboard {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1rem;

    padding: 1rem;
    background-color: $side;
    border-radius: 1rem;

    &__logout {
        padding: 0.25rem 1rem;
        background-color: $dark;
        color: $primary-text;
        columns: $primary-text;
        border: none;
    }

    &__form {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    &__input {
        padding: 0.25rem;
    }

    &__add {
        width: 1.5rem;
    }

    &__warning {
        display: flex;
        gap: 0.5rem;
    }

    &__warning-icon {
        width: 1.5rem;
    }

    &__new-deck {
        margin: 0.5rem;
        font-size: 1.5rem;
        background-color: transparent;
        border: none;
        color: $primary-text;
    }

    &__decklist {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        border: 2px solid $dark;
        width: 100%;
    }

    &__name {
        padding: 0.5rem 2rem;
        white-space: nowrap;
    }

    &__dropdown {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
    }

    &__dropdown-icon {
        position: relative;
        height: 1rem;
    }

    &__dropdown-item {
        position: absolute;
        right: -16px;
        top: calc(50% + 0.5rem);
        z-index: 2;
        padding: 0.25rem 1.5rem;
        background-color: $side;
        box-shadow: $box-shadow;

        &:hover {
            @extend .dashboard__dropdown-item;
            border: 2px solid $background;
        }
    }

    &__dropdown-text {
        white-space: nowrap;
    }
}