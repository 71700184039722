@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.login-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    background-color: $side;
    border-radius: 1rem;

    &__span {
        color: $secondary;
        cursor: pointer;
    }
}

.login {
    display: flex;
    flex-direction: column;
    width: 22rem;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.1);

    border-radius: 0.5rem;
    padding: 2rem 1.5rem;
    margin-bottom: 1rem;

    &__button {
        margin-top: 1rem;
        background-color: $accent;
        border: 1px solid $accent;
        color: #fff;
        border-radius: 0.5rem;
        padding: 0.5rem;
        font-size: inherit;
        cursor: pointer;
        transition: background-color 0.15s;
    }

    &__message {
        border-left: 5px solid #ddd;
        padding: 0.75rem;
        margin-top: 2rem;
        color: $primary-text;
    }
}