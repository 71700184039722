@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.card {
    &__bar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        gap: 1rem;

        &:hover {
            @extend .card__bar;
            background-color: $dark;
        }

        &--selected {
            @extend .card__bar;
            background-color: $accent;
        }
    }

    &__hoverImg {
        position: absolute;
        z-index: 2;
        height: 12rem;
        left: -140px;
        border-radius: 10px;
    }

    &__quantity {
        background-color: $side;
        color: $primary-text;
        border: none;
        width: 2rem;
        text-align: center;
        padding: 0 0.25rem;
    }
    &__name {
        margin: 2px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    &__dropdown {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
    }

    &__dropdown-icon {
        position: relative;
        height: 1rem;
    }

    &__dropdown-item {
        position: absolute;
        right: -16px;
        top: calc(50% + 0.5rem);
        z-index: 2;
        padding: 0.25rem 1.5rem;
        background-color: $side;
        box-shadow: $box-shadow;

        &:hover {
            @extend .card__dropdown-item;
            border: 2px solid $background;
        }
    }

    &__dropdown-text {
        white-space: nowrap;
    }
}

//hide stepper arrows for input element: type=number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
