@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.loading-icon {

    border: 4px solid $dark;
    border-top: 4px solid $primary-text;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 2s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}