@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables" as *;

.sidebar {
    height: calc(100vh - 4rem);
    background-color: $side;
    position: fixed;
    top: 4rem;
    z-index: 2;
    box-shadow: $box-shadow;

    display: flex;
    gap: 0.5rem;
    padding: 2rem 0;

    &__bar {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;

        width: 4rem;
        height: 100%;
        background-color: $side;
    }

    &__container {
        width: 100%;
        padding: 1rem;

        &--active {
            @extend .sidebar__container;
            background-color: $dark;
        }
    }

    &__icon {
        height: 2rem;
    }

    &__charts {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        width: 42vw;
        border-right: 1px solid $secondary;
        overflow-y: scroll;

        /* Customize the scrollbar track */
        &::-webkit-scrollbar {
            width: 8px;
        }

        /* Customize the scrollbar thumb (the draggable part) */
        &::-webkit-scrollbar-thumb {
            background-color: $secondary;
            border-radius: 5px;
        }

        /* Customize the scrollbar thumb when the user hovers over it */
        &::-webkit-scrollbar-thumb:hover {
            background-color: $primary-text;
        }
    }

}