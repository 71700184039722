@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../assets/fonts/TitilliumWeb-Regular.woff2') format('woff2'),
        url('../../assets/fonts/TitilliumWeb-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TitilliumWeb';
    src: url('../../assets/fonts/TitilliumWeb-SemiBold.woff2') format('woff2'),
        url('../../assets/fonts/TitilliumWeb-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}